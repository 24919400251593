import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import { deepImmutableCopy } from "@/utils/util";
import GenericConfigTabExtender from "./genericConfigTabExtender";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {}

export default function CmsImageWithMarkersItemConfig(
  props: CmsGenericConfigProps
) {
  const tCms = useCmsTranslation();
  const { configModalTabs, filterConfigModalFieldsFromConfigModalTabs } =
    useConfigModalTabs(props.attributes, props.component);

  // filter elements that you want to render with custom logic
  const filteredConfigModalTabs = filterConfigModalFieldsFromConfigModalTabs(
    deepImmutableCopy(configModalTabs),
    ["markerPosition"]
  );

  const storeSetting = props.currentElement["cfgMarkerPosition"];

  return (
    <GenericConfigTabs
      configModalTabs={filteredConfigModalTabs}
      onChange={props.onChange}
      key={storeSetting}
    >
      <GenericConfigTabExtender tabName="options" index={0}>
        <>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-xCoordinate")}
            description={tCms("imgWithMarkers-xCoordinateDescription")}
          >
            <PbInput
              defaultValue={props.currentElement.markerPosition.x}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("markerPosition", {
                  x: parseInt(e.target.value, 10),
                  y: props.currentElement.markerPosition.y,
                })
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-yCoordinate")}
            description={tCms("imgWithMarkers-yCoordinateDescription")}
          >
            <PbInput
              defaultValue={props.currentElement.markerPosition.y}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("markerPosition", {
                  x: props.currentElement.markerPosition.x,
                  y: parseInt(e.target.value, 10),
                })
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-width")}
            description={tCms("imgWithMarkers-widthDescription")}
          >
            <PbInput
              defaultValue={props.currentElement.width}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("width", parseInt(e.target.value, 10))
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-height")}
            description={tCms("imgWithMarkers-heightDescription")}
          >
            <PbInput
              defaultValue={props.currentElement.height}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("height", parseInt(e.target.value, 10))
              }
            />
          </ModalInputWrapper>
        </>
      </GenericConfigTabExtender>
    </GenericConfigTabs>
  );
}
