import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import { valueFromStoreSetting } from "@/services/ceSettings/ceSettingsService";
import { useAppSelector } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { deepImmutableCopy } from "@/utils/util";
import { SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect } from "react";
import GenericConfigTabExtender from "./genericConfigTabExtender";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {}

export default function CmsTextWithImageConfig(props: CmsGenericConfigProps) {
  const sideMenu = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings?.sideMenu
  );
  const contentElementSetting = useAppSelector(
    (state) => state.cmsGeneral.configModal.contentElementSetting
  );
  const tCms = useCmsTranslation();
  const { configModalTabs, filterConfigModalFieldsFromConfigModalTabs } =
    useConfigModalTabs(props.attributes, props.component);

  // filter elements that you want to render with custom logic
  const filteredConfigModalTabs = filterConfigModalFieldsFromConfigModalTabs(
    deepImmutableCopy(configModalTabs),
    ["cfgStrTextToImageRatio"]
  );

  const storeSettings = props.currentElement[
    "cfgStrTextToImageRatio"
  ] as StoreSetting;

  const getOptions = useCallback(() => {
    if (
      !contentElementSetting?.setting ||
      !contentElementSetting.setting["ratio1"]
    ) {
      return [];
    }
    const ratio1: string = contentElementSetting.setting["ratio1"];
    const ratio2: string = contentElementSetting.setting["ratio2"];
    const ratio3: string = contentElementSetting.setting["ratio3"];
    const ratio4: string = contentElementSetting.setting["ratio4"];

    return [
      { name: `${tCms("configModal-default")} (6/6)`, value: "default" },
      { name: ratio1, value: ratio1 },
      { name: ratio2, value: ratio2 },
      { name: ratio3, value: ratio3 },
      { name: ratio4, value: ratio4 },
    ];
  }, [contentElementSetting?.setting, tCms]);

  const valueExistsInOptions = useCallback(() => {
    const values = getOptions();

    const selectedValue = valueFromStoreSetting(storeSettings);

    const selectedValueExists =
      values?.findIndex(
        (item) => selectedValue && item.value === String(selectedValue)
      ) >= 0;

    if (
      selectedValue &&
      String(selectedValue) !== "default" &&
      !selectedValueExists
    ) {
      props.onChange("cfgStrTextToImageRatio", {
        ...storeSettings,
        values: ["default"],
      });
    }
  }, [getOptions, props, storeSettings]);

  const ratioChangeHandler = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange("cfgStrTextToImageRatio", {
      ...storeSettings,
      values: [selectedValue],
    });
  };

  useEffect(() => {
    valueExistsInOptions();
  }, [props.currentElement, valueExistsInOptions]);

  return (
    <GenericConfigTabs
      configModalTabs={filteredConfigModalTabs}
      onChange={props.onChange}
      key={storeSettings?.values?.at(0)}
    >
      <GenericConfigTabExtender tabName="options" index={3}>
        <ModalInputWrapper
          label={tCms("configModal-cfgStrTextToImageRatio")}
          description={tCms("configModal-cfgStrTextToImageRatioDescription")}
          fullWidth={sideMenu}
          hiddenDescription={sideMenu}
          showTooltip={sideMenu}
        >
          <PbDropdown
            dropdownList={getOptions()}
            onChange={ratioChangeHandler}
            defaultValue={
              storeSettings?.values?.length > 0
                ? storeSettings.values.at(0) + ""
                : "default"
            }
            itemNameKey="name"
            itemValueKey="value"
          ></PbDropdown>
        </ModalInputWrapper>
      </GenericConfigTabExtender>
    </GenericConfigTabs>
  );
}
