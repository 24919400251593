import { ConfigModalEntry } from "@/components/cms/cmsConfigModal/cmsConfigModal";
import CmsGenericConfig from "@/components/cms/cmsConfigModal/cmsGenericConfig";
import dynamic from "next/dynamic";
import CmsImageWithMarkersConfig from "./cmsImageWithMarkersConfig";
import CmsImageWithMarkersItemConfig from "./cmsImageWithMarkersItemConfig";
import CmsTextWithImageConfig from "./cmsTextWithImageConfig";

export interface CmsConfigRendererProps {
  attributes: ConfigModalEntry[];
  onChange: (attributeName: string, value: any) => void;
  component?: string;
  currentElement: any;
}

const CmsFormConfig = dynamic(
  () => import("@/components/cms/cmsConfigModal/cmsFormConfig")
);

const CUSTOM_COMPONENT_KEY = "cfgCustomComponentName";

export default function CmsConfigRenderer(props: CmsConfigRendererProps) {
  const customConfigComponents: any = {
    default: CmsGenericConfig,
    form: CmsFormConfig,
    textWithImage: CmsTextWithImageConfig,
    imageWithMarkers: CmsImageWithMarkersConfig,
    imageWithMarkersItem: CmsImageWithMarkersItemConfig,
    // add additional custom config components
  };

  const getConfigComponentName = () => {
    const cfgCustomComponentName = props.attributes.find(
      (attribute) => attribute.name === CUSTOM_COMPONENT_KEY
    );
    const customComponentKey =
      cfgCustomComponentName?.attributes?.default ?? "default";
    if (customComponentKey !== "default") {
      return customConfigComponents[customComponentKey];
    }
    switch (props.component) {
      case "pb.cfrm":
        return customConfigComponents.form;
      case "pb.ctwi":
        return customConfigComponents.textWithImage;
      case "pb.cmgwthmrkrs":
        return customConfigComponents.imageWithMarkers;
      case "pb.mgwthmrkrstm":
        return customConfigComponents.imageWithMarkersItem;
      default:
        return customConfigComponents.default;
    }
  };

  const CustomConfigComponent = getConfigComponentName();

  return (
    <CustomConfigComponent
      attributes={props.attributes.filter(
        (attribute) => attribute.name !== CUSTOM_COMPONENT_KEY
      )}
      onChange={props.onChange}
      component={props.component}
      currentElement={props.currentElement}
    ></CustomConfigComponent>
  );
}
