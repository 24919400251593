import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * Scrolls to anchor.
 * #test -> will jump to an element with id #anchor-test.
 * This "anchor-Manipulation" prevents the browser from jumping immediately to the
 * (maybe wrong) position of the element. The one (and only) jump to the anchor is done
 * by the useEffect here.
 *
 * Scrolling to an Element:
 * 1. Search for last-element on page.
 * 2. After last-element on page is found, check if any element is loaded async (like forms) and still loading.
 * 3. After all elements have loaded, perform the scroll to the element.
 * 4. To delay the scroll until 3., the real # is: anchor-richtext-11. While the browser only shows: #richtext-11.
 *
 *
 * @param {number} additionalOffset added to offSet of globalSettings
 */
function useScrollTo(additionalOffset = 0) {
  const router = useRouter();
  const scrollToOffset = 0;

  useEffect(() => {
    // Intervals
    let searchLastContentElement: ReturnType<typeof setInterval>;
    let checkLoadingContentElements: ReturnType<typeof setInterval>;

    // Start process of scrolling
    if (window.location.hash) {
      const sanitizeHash = (rawHash: string) => {
        let sanitizedHash = rawHash;
        // Remove query-parameters like ?edit=true
        if (rawHash.indexOf("?") !== -1) {
          sanitizedHash = rawHash.split("?")[0];
        }
        sanitizedHash = sanitizedHash.split("#")[1];
        return `#anchor-${sanitizedHash}`;
      };

      const scrollToElement = () => {
        setTimeout(() => {
          const element = document.querySelector(
            `${sanitizeHash(window.location.hash)}`
          ) as HTMLElement;
          if (element) {
            document
              .getElementById("layout")
              ?.setAttribute("data-tempdisableanimations", "true");
            window.scrollTo({
              behavior:
                (globalConfig?.scrolling.scrollEffect as ScrollBehavior) ||
                "smooth",
              top: element.offsetTop - (scrollToOffset + additionalOffset),
            });
          } else {
            global.log.warn(
              `Couldn't find and scroll to: (#anchor-)${sanitizeHash(
                window.location.hash
              )}`
            );
          }
        }, 500);
      };

      const searchForAsyncContentElements = () => {
        // Check for content-elements with data-is-loading-Attribute
        const asyncContentElements = document.querySelectorAll(
          ".content-element[data-is-loading]"
        );
        if (!asyncContentElements) {
          // No async content-element exists.
          scrollToElement();
        } else {
          // Check for content-elements with data-is-loading="true"
          let contentElementsStillLoading = document.querySelectorAll(
            'div[data-is-loading="true"]'
          );
          if (contentElementsStillLoading.length > 0) {
            let asyncContentCheckCount = 0;
            checkLoadingContentElements = setInterval(() => {
              contentElementsStillLoading = document.querySelectorAll(
                'div[data-is-loading="true"]'
              );
              asyncContentCheckCount++;
              if (
                contentElementsStillLoading.length === 0 ||
                asyncContentCheckCount > 5
              ) {
                clearInterval(checkLoadingContentElements);
                scrollToElement();
              }
            }, 300);
          } else {
            scrollToElement();
          }
        }
      };

      // Check for .last-element
      let lastContentElement = document.querySelectorAll(
        ".content-element.last-element"
      );
      if (lastContentElement.length === 0) {
        // Periodically search for .last-element
        let lastContentSearchCount = 0;
        searchLastContentElement = setInterval(() => {
          lastContentElement = document.querySelectorAll(
            ".content-element.last-element"
          );
          lastContentSearchCount++;
          if (lastContentElement.length > 0 || lastContentSearchCount > 5) {
            clearInterval(searchLastContentElement);
            searchForAsyncContentElements();
          }
        }, 300);
      } else {
        searchForAsyncContentElements();
      }
    }

    return () => {
      clearInterval(searchLastContentElement);
      clearInterval(checkLoadingContentElements);
    };
  }, [router]);
}

export default useScrollTo;
